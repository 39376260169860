import PerfectScrollbar from 'perfect-scrollbar';


const container = document.querySelector('#container');
const sidebar = document.querySelector('#sidebar');
const abonnementList = document.querySelector('#abonnements-list');
const coachDetails = document.querySelector('#coach-detail');
const productGallery = document.querySelector('.product-gallery-scroll');

if (sidebar) {
  const sb = new PerfectScrollbar('#sidebar');
}

if (container) {
  const ps = new PerfectScrollbar('#container');
}

if (abonnementList) {
  const ps = new PerfectScrollbar('#abonnements-list');
}

if (coachDetails) {
  const ps = new PerfectScrollbar('#coach-detail');
}

if (productGallery) {
  const ps = new PerfectScrollbar('.product-gallery-scroll');
}
