import {Controller} from "stimulus"

export default class extends Controller {

  static targets = [
    "weight",
    "decrementWeightButton",
    "year",
    "shutdownYear",
    "decrementYearButton",
    "decrementShutdownYearButton",
    "height",
    "decrementHeightButton",
    "otherQuestionInput",
    "toggleOtherQuestion",
    "antecedentsInput",
    "toggleAntecedents",
    "antecedentsChirurgicauxInput",
    "toggleAntecedentsChirurgicaux",
    "traitementInput",
    "toggleTraitement",
    "allergiesInput",
    "toggleAllergies",
    "indicationsInput",
    "toggleIndications",
    "precautionsInput",
    "togglePrecautions",
    "medicalBackground",
    "regularPhysicalActivity",
    "surgicalHistory",
    "knownAllergies",
    "contraindications",
    "specialPrecautions",
  ];

  initialize() {
    this.weight_value = 0;
    this.height_value = 0;
    this.year_value = 0;
    this.shutdown_year_value = 0;
    this.updateWeight();
    this.updateHeight();
    this.updateYear();
    this.updateShutdownYear()
    this.displayAntecedents();
    this.displayOtherQuestions();
    this.displayAntecedentsChirurgicaux();
    this.displayAllergies();
    this.displayIndications();
    this.displayPrecautions();
  }

  //weight
  decrementWeight() {
    if (this.weight_value !== 0) {
      this.weight_value--;
      this.updateWeight();
    }
  }

  incrementWeight() {
    this.weight_value++;
    this.updateWeight();
  }

  updateWeight() {
    let input = this.weightTargets[0];
    if (input) {
      input.value = this.weight_value;
    }

    let button = this.decrementWeightButtonTargets[0];
    if (button) {
      if (this.weight_value !== 0) {
        if (button.classList.contains('bg-gray')) {
          button.classList.remove('bg-gray');
          button.classList.add('bg-black');
        }
        if (!button.classList.contains('bg-black')) {
          button.classList.remove('bg-black');
        }
      }
      if (this.weight_value === 0) {
        button.classList.remove('bg-black');
        button.classList.add('bg-gray');
      }
    }
  }

  //Height
  decrementHeight() {
    if (this.height_value !== 0) {
      this.height_value--;
      this.updateHeight();
    }
  }

  incrementHeight() {
    this.height_value++;
    this.updateHeight();
  }

  updateHeight() {
    const input = this.heightTargets[0];
    if (input) {
      input.value = this.height_value;
    }

    let button = this.decrementHeightButtonTargets[0];
    if (button) {
      if (this.height_value !== 0) {
        if (button.classList.contains('bg-gray')) {
          button.classList.remove('bg-gray');
          button.classList.add('bg-black');
        }
        if (!button.classList.contains('bg-black')) {
          button.classList.remove('bg-black');
        }
      }
      if (this.height_value === 0) {
        button.classList.remove('bg-black');
        button.classList.add('bg-gray');
      }
    }
  }


  //Year
  decrementYear() {
    if (this.year_value !== 0) {
      this.year_value--;
      this.updateYear();
    }
  }

  incrementYear() {
    this.year_value++;
    this.updateYear();
  }

  updateYear() {
    let input = this.yearTargets[0];
    if (input) {
      input.value = this.year_value;
    }

    let button = this.decrementYearButtonTargets[0];
    if (button) {
      if (this.year_value !== 0) {
        if (button.classList.contains('bg-gray')) {
          button.classList.remove('bg-gray');
          button.classList.add('bg-black');
        }
        if (!button.classList.contains('bg-black')) {
          button.classList.remove('bg-black');
        }
      }
      if (this.year_value === 0) {
        button.classList.remove('bg-black');
        button.classList.add('bg-gray');
      }
    }
  }

  //Shutdown Year
  decrementShutdownYear() {
    if (this.shutdown_year_value !== 0) {
      this.shutdown_year_value--;
      this.updateShutdownYear();
    }
  }

  incrementShutdownYear() {
    this.shutdown_year_value++;
    this.updateShutdownYear();
  }

  updateShutdownYear() {
    let input = this.shutdownYearTargets[0];
    if (input) {
      input.value = this.shutdown_year_value;
    }

    let button = this.decrementShutdownYearButtonTargets[0];
    if (button) {
      if (this.shutdown_year_value !== 0) {
        if (button.classList.contains('bg-gray')) {
          button.classList.remove('bg-gray');
          button.classList.add('bg-black');
        }
        if (!button.classList.contains('bg-black')) {
          button.classList.remove('bg-black');
        }
      }
      if (this.shutdown_year_value === 0) {
        button.classList.remove('bg-black');
        button.classList.add('bg-gray');
      }
    }
  }


  //other question
  displayOtherQuestions() {
    let state = this.regularPhysicalActivityTarget;
    let input = this.otherQuestionInputTargets[0]
    if (state.value === 'others') {
      input.classList.remove('hidden');
    } else {
      input.classList.add('hidden');
    }

  }

  //Antecedents
  displayAntecedents() {
    let state = this.medicalBackgroundTarget;
    let input = this.antecedentsInputTargets[0]
    if (state.checked) {
      input.classList.remove('hidden');
    } else {
      input.classList.add('hidden');
    }
  }

  //Antecedents Chirurgicaux
  displayAntecedentsChirurgicaux() {
    let state = this.surgicalHistoryTarget;
    let input = this.antecedentsChirurgicauxInputTargets[0]

    if (state.checked) {
      input.classList.remove('hidden');
    } else {
      input.classList.add('hidden');
    }

  }

  //Traitement en cours
  displayTraitement() {
    let state = this.toggleTraitementTargets[0];
    let input = this.traitementInputTargets[0]

    if (state.checked) {
      input.classList.remove('hidden');
    } else {
      input.classList.add('hidden');
    }

  }


  //Allergies connues
  displayAllergies() {
    let state = this.knownAllergiesTarget;
    let input = this.allergiesInputTargets[0]

    if (state.checked) {
      input.classList.remove('hidden');
    } else {
      input.classList.add('hidden');
    }

  }

  //Contre-indications
  displayIndications() {
    let state = this.contraindicationsTarget;
    let input = this.indicationsInputTargets[0]

    if (state.checked) {
      input.classList.remove('hidden');
    } else {
      input.classList.add('hidden');
    }

  }

  //Précautions particulières à prendre
  displayPrecautions() {
    let state = this.specialPrecautionsTarget;
    let input = this.precautionsInputTargets[0]

    if (state.checked) {
      input.classList.remove('hidden');
    } else {
      input.classList.add('hidden');
    }
  }
}
