import TimePicker from 'tui-time-picker';

const startTime = document.querySelector('#start-time-picker-container');
if (startTime) {

  const instance = new TimePicker(startTime, {
    inputType: 'selectbox',
    showMeridiem: false,
  });
  instance.getHour();
}

const endTime = document.querySelector('#end-time-picker-container');
if (endTime) {

  const instance = new TimePicker(endTime, {
    inputType: 'selectbox',
    showMeridiem: false,
  });
  instance.getHour();
}

