import iconPlus from '../images/icon-plus.svg'
import iconMoins from '../images/icon-moins.svg'
function initAcc(elem, option) {
  document.addEventListener('click', function (e) {
    if (
      !e.target.matches(elem + ' .a-btn') &&
      !e.target.matches(elem + ' .text-xl') &&
      !e.target.matches(elem + ' img')
    ) return;
    let parentElement = e.target.parentElement;
    if(e.target.matches(elem + ' .text-xl') || e.target.matches(elem + ' img'))
      parentElement = e.target.parentElement.parentElement;

    if (!parentElement.classList.contains('active')) {
      if (option === true) {
        const elementList = document.querySelectorAll(elem + ' .a-container');
        const elementListImage = document.querySelectorAll(elem + ' .a-container img');
        Array.prototype.forEach.call(elementList, function (e) {
          e.classList.remove('active');
        });
        Array.prototype.forEach.call(elementListImage, function (e) {
          e.src = iconPlus;
        });
      }
      parentElement.classList.add('active');
      parentElement.querySelector('img').src = iconMoins;
    } else {
      parentElement.classList.remove('active');
      parentElement.querySelector('img').src = iconPlus;
    }
  });
}

initAcc('.accordion', true);
