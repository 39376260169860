import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["inputCoach", "inputBeCoached", "beCoachDiv", "coachDiv"];

  login(event) {
    event.preventDefault();
    window.document.location.pathname = '/formule.html';
  }


  coach(event) {
    const input = this.inputCoachTargets[0]
    const parent = this.coachDivTargets[0];
    if (!input.checked) {
      //remove gradient bg, add grey bg
      parent.classList.add('bg-gradient-to-b', 'from-black', 'to-soft_dark', 'text-white');
      parent.classList.remove('text-black', 'bg-light_gray_4');

      //add gradient to the other input
      this.beCoachDivTargets[0].classList.remove('bg-gradient-to-b', 'from-black', 'to-soft_dark');
      this.beCoachDivTargets[0].classList.add('text-black', 'bg-light_gray_4');
      input.checked = "false";
    }
  }

  be_coached(event) {
    const input = this.inputBeCoachedTargets[0];
    const parent = this.beCoachDivTargets[0];
    if (!input.checked) {
      //remove gradient bg, add grey bg
      parent.classList.add('bg-gradient-to-b', 'from-black', 'to-soft_dark', 'text-white');
      parent.classList.remove('text-black', 'bg-light_gray_4');

      //add gradient to the other input
      this.coachDivTargets[0].classList.remove('bg-gradient-to-b', 'from-black', 'to-soft_dark');
      this.coachDivTargets[0].classList.add('text-black', 'bg-light_gray_4');
      input.checked = "false";
    }
  }
}
