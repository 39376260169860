import {Controller} from 'stimulus'
import { encrypt } from '../src/encrypt'
import {addSpaceOrComma} from "../src/add_space_or_comma";

export default class extends Controller {
  static targets = [
    'subscriptionType',
    'amount',
    'month',
    'frequency',
    'amountToBePay',
    'reduction',
    'trainingWithOnePerson',
    'trainingWithTwoPeople',
    'alonePrice_0',
    'alonePrice_1',
    'alonePrice_2',
    'alonePrice_3',
    'aloneMonth_0',
    'aloneMonth_1',
    'aloneMonth_2',
    'aloneMonth_3'
  ]

  connect() {
    this.getSubscriptionType()
    this.updateAmountToBePay()
    this.trainingWithOnePersonTarget.hidden = true
    this.trainingWithTwoPeopleTarget.hidden = true
  }

  getSubscriptionType() {
    if (this.subscriptionTypeTarget.value === 'with_one_person') {
      this.trainingWithOnePersonTarget.hidden = false
      this.reductionTarget.hidden = true
      this.trainingWithTwoPeopleTarget.hidden = true

      let total_amount = this.totalAmountWithOnePerson() * this.monthTarget.value
      this.amountTarget.innerHTML = addSpaceOrComma(total_amount)
      this.amountToBePayTarget.value = encrypt(total_amount)
    } else if (this.subscriptionTypeTarget.value === 'with_two_people'){
      this.trainingWithTwoPeopleTarget.hidden = false
      this.trainingWithOnePersonTarget.hidden = true
      this.reductionTarget.hidden = true

      let total_amount = this.totalAmountWithTwoPeople() * this.monthTarget.value
      this.amountTarget.innerHTML = addSpaceOrComma(total_amount)
      this.amountToBePayTarget.value = encrypt(total_amount)
    }
    else {
      this.reductionTarget.hidden = false
      this.trainingWithOnePersonTarget.hidden = true
      this.trainingWithTwoPeopleTarget.hidden = true
      let total_amount = (5000 * 1) * (this.frequencyTarget.innerHTML * 4) * this.monthTarget.value
      this.amountTarget.innerHTML = addSpaceOrComma(total_amount)
      this.amountToBePayTarget.value = encrypt(total_amount)
      if(this.aloneMonth_0Target.innerHTML === this.monthTarget.value){
        this.amountTarget.innerHTML = addSpaceOrComma(this.alonePrice_0Target.innerHTML)
        this.amountToBePayTarget.value = encrypt(this.alonePrice_0Target.innerHTML)
      }
      if(this.aloneMonth_1Target.innerHTML === this.monthTarget.value){
        this.amountTarget.innerHTML = addSpaceOrComma(this.alonePrice_1Target.innerHTML)
        this.amountToBePayTarget.value = encrypt(this.alonePrice_1Target.innerHTML)
      }
      if(this.aloneMonth_2Target.innerHTML === this.monthTarget.value){
        this.amountTarget.innerHTML = addSpaceOrComma(this.alonePrice_2Target.innerHTML)
        this.amountToBePayTarget.value = encrypt(this.alonePrice_2Target.innerHTML)
      }
      if(this.aloneMonth_3Target.innerHTML === this.monthTarget.value){
        this.amountTarget.innerHTML = addSpaceOrComma(this.alonePrice_3Target.innerHTML)
        this.amountToBePayTarget.value = encrypt(this.alonePrice_3Target.innerHTML)
      }
    }
  }

  updateAmountToBePay() {
    if (this.subscriptionTypeTarget.value === 'with_one_person'){

      const amountToBePay = this.monthTarget.value * this.totalAmountWithOnePerson()
      this.amountTarget.innerHTML = addSpaceOrComma(amountToBePay)
      this.amountToBePayTarget.value = encrypt(amountToBePay)
    }else if (this.subscriptionTypeTarget.value === 'with_two_people'){
      const amountToBePay = this.monthTarget.value * this.totalAmountWithTwoPeople()
      this.amountTarget.innerHTML = addSpaceOrComma(amountToBePay)
      this.amountToBePayTarget.value = encrypt(amountToBePay)
    }else{
      if(this.aloneMonth_0Target.innerHTML === this.monthTarget.value){
        this.amountTarget.innerHTML = addSpaceOrComma(this.alonePrice_0Target.innerHTML)
        this.amountToBePayTarget.value = encrypt(this.alonePrice_0Target.innerHTML)
      }
      if(this.aloneMonth_1Target.innerHTML === this.monthTarget.value){
        this.amountTarget.innerHTML = addSpaceOrComma(this.alonePrice_1Target.innerHTML)
        this.amountToBePayTarget.value = encrypt(this.alonePrice_1Target.innerHTML)
      }
      if(this.aloneMonth_2Target.innerHTML === this.monthTarget.value){
        this.amountTarget.innerHTML = addSpaceOrComma(this.alonePrice_2Target.innerHTML)
        this.amountToBePayTarget.value = encrypt(this.alonePrice_2Target.innerHTML)
      }
      if(this.aloneMonth_3Target.innerHTML === this.monthTarget.value){
        this.amountTarget.innerHTML = addSpaceOrComma(this.alonePrice_3Target.innerHTML)
        this.amountToBePayTarget.value = encrypt(this.alonePrice_3Target.innerHTML)
      }
    }
  }

  totalAmountWithOnePerson(){
    let total_amount = (4000 * 2 * this.frequencyTarget.innerHTML * 4)
    return total_amount
  }

  totalAmountWithTwoPeople(){
    let total_amount = (3500 * 3) * (this.frequencyTarget.innerHTML * 4)
    return total_amount
  }
}
