import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["question"];

  collapse(e) {

    let i = 0;

    /*for (i = 0; i < coll.length; i++) {
      coll[i].addEventListener("click", function () {
        this.classList.remove("bg-gradient-to-b");
        let content = this.nextElementSibling;
        if (!content.classList.contains("hidden")) {
          content.classList.add("hidden");
        } else {
          content.style.display = "block";
        }
      });
    }*/

  }
}

