import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["day"];

  selectDay = (event) => {
    const toggleDay = event.target;

    const isSelected = toggleDay.classList.contains("bg-red");
    if (!isSelected) {
      toggleDay.classList.remove("text-black");
      toggleDay.classList.add("bg-red", "text-white");
    } else {
      toggleDay.classList.add("text-white");
      toggleDay.classList.remove("bg-red", "text-white");
    }
  }
}
