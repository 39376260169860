//redirecting to the index page on click to the logo in sidebar
if (document.querySelector('#nav-logo')) {
  document.querySelector('#nav-logo').addEventListener('click', () => {
    window.document.location.pathname = '/';
  })
}

/*
  collapsible item in faq page
 */

const coll = document.getElementsByClassName("collapsible");

if (coll) {
  let i;

  for (i = 0; i < coll.length; i++) {
    coll[i].addEventListener("click", function () {
      this.classList.remove("bg-gradient-to-b");
      let content = this.nextElementSibling;
      if (!content.classList.contains("hidden") ) {
        content.classList.add("hidden");
      } else {
        content.style.display = "block";
      }
    });
  }
}
