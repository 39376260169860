const modal = document.querySelectorAll('.main-modal');
const closeButton = document.querySelector('.modal-close');
const currentModal = document.querySelectorAll('.open-modal');
let currentDialog = null;

const openCurrentModal = function (e, i) {
  e.preventDefault();
  let target = document.querySelector(`.main-modal-${i}`);
  if (target === null) {
    target = document.querySelector(`.main-modal`);
  }else {
    target.classList.remove('fadeOut')
    target.classList.add('fadeIn');
    target.style.display = null;
  }

  currentDialog = target;
  closeButton.addEventListener('click', closeCurrentModal)
  if (closeButton)
    if (currentDialog)
      currentDialog.addEventListener('click', closeCurrentModal);
  document.querySelector('.modal-stop').addEventListener('click', stopPropagation)
}

const closeCurrentModal = function (e) {
  if (currentDialog === null) return;
  e.preventDefault();
  currentDialog.classList.remove('fadeIn');
  currentDialog.classList.add('fadeOut');
  window.setTimeout(() => {
    currentDialog.style.display = 'none';
    currentDialog = null;
  }, 500);
  currentDialog.removeEventListener('click', closeCurrentModal);
  if (closeButton)
    closeButton.removeEventListener('click', closeCurrentModal);
  document.querySelector('.modal-stop').removeEventListener('click', stopPropagation)
}

const stopPropagation = function (e) {
  e.stopPropagation();
}

currentModal.forEach((modal, i) => {
  modal.addEventListener('click', (e) => openCurrentModal(e, i));
})

window.addEventListener('keydown', (e) => {
  if (e.key === 'Escape' || e.key === 'Esc') {
    closeCurrentModal(e)
  }
})
