import CryptoJS from 'crypto-js';


function encrypt(text) {
  const encrypted = CryptoJS.AES.encrypt(text.toString(), "3cf014c91af6f9bd7f5a98b0c75a9b8a")
  return encrypted
}

export {encrypt}

