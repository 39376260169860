import { Controller } from 'stimulus'

export default class extends Controller {

    static targets = [ "menu", "menuBackdrop" ]

    initialize() {
      this.showMenu = false;
    }

    toggleDrawerMenu() {
      const backContainer = document.getElementsByTagName("body");
      this.showMenu = !this.showMenu;
      if(this.showMenu){
        this.menuTargets.forEach((element, index) => {
            element.classList.remove("-translate-x-72");
        });
        this.menuBackdropTargets.forEach((element, index) => {
            element.classList.remove("hidden");
        });
        if (backContainer) {
          backContainer[0].classList.add("overflow-hidden");
        }
      }else{
        this.menuTargets.forEach((element, index) => {
            element.classList.add("-translate-x-72");
        });
        this.menuBackdropTargets.forEach((element, index) => {
            element.classList.add("hidden");
        });
        if (backContainer) {
          backContainer[0].classList.remove("overflow-hidden");
        }
      }
    }

}
