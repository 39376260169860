import { Controller } from 'stimulus'

export default class extends Controller {
  close() {
   $('.notification_message').remove()
  }
  fadeOut() {
    setTimeout(function() {
      $(".notification_message").fadeOut().empty();
    }, 5000);
  }
}
