// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import $ from "jquery"

import '../src/accordion'
import '../src/agenda'
import '../src/countdown'
import '../src/menu'
import '../src/modal'
import '../src/scroolbar'
import '../src/slider'
import '../src/timepicker'
import '../src/utils'
import 'controllers'

Rails.start()
ActiveStorage.start()
require("../scss/application.scss")
require("jquery")
require("@nathanvda/cocoon")
require.context('../images', true)
