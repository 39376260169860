import {tns} from "/node_modules/tiny-slider/src/tiny-slider"


var svg = `
<svg xmlns="http://www.w3.org/2000/svg" width="12.621" height="22.243" viewBox="0 0 12.621 22.243">
  <path id="Icon_feather-chevron-left" data-name="Icon feather-chevron-left" d="M22.5,27l-9-9,9-9" transform="translate(-12 -6.879)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
</svg>
`


if (document.querySelector(".slider-ads")) {
  const slider_ads = tns({
    container: '.slider-ads',
    items: 1,
    slideBy: 1,
    loop: true,
    controlsText: ["<div>" + svg + "</div>", "<div>" + svg + "</div>"],
    controlsPosition: "bottom",
    navPosition: "bottom",
    mouseDrag: true,
    autoplay: true,
    nextButton: false,
    prevButton: false
  });
}

if (document.querySelector(".formule-slider")) {
  const formule_slider = tns({
    container: '.formule-slider',
    items: 2,
    loop: true,
    controls: false,
    mouseDrag: true,
    disable: true,
    touch: true,
    slideBy: 1,
    nextButton: false,
    prevButton: false,
    responsive: {
      640: {
        disable: false,
      },
      1024: {
        disable: true,
      }
    }
  });
}

if (document.querySelector(".how-is-work-slider")) {
  const slider_how_is_work = tns({
    container: '.how-is-work-slider',
    items: 1,
    slideBy: 1,
    autoplay: false,
    loop: false,
    controlsText: ["", ""],
    mouseDrag: true,
    controlsPosition: "top",
    navPosition: "top",
    nextButton: false,
    prevButton: false
  });
  document.querySelector("#how-is-work-section-0").onclick = function () {
    slider_how_is_work.goTo(0);
  };
  document.querySelector("#how-is-work-section-image-0").onclick = function () {
    slider_how_is_work.goTo(0);
  };
  document.querySelector("#how-is-work-section-bar-0").onclick = function () {
    slider_how_is_work.goTo(0);
  };

  document.querySelector("#how-is-work-section-1").onclick = function () {
    slider_how_is_work.goTo(1);
  }

  document.querySelector("#how-is-work-section-image-1").onclick = function () {
    slider_how_is_work.goTo(1);
  }

  document.querySelector("#how-is-work-section-bar-1").onclick = function () {
    slider_how_is_work.goTo(1);
  }
  const customizedFunction = function (info, eventName) {
    if (info.index == 0) {
      document.querySelector("#how-is-work-section-bar-0").className = "cursor-pointer rounded-md w-2.5 bg-red flex-1 shadow-sm transition-all"
      document.querySelector("#how-is-work-section-bar-1").className = "cursor-pointer w-1 bg-light_gray_3 flex-1 transition-all"
      document.querySelector("#how-is-work-section-image-0").className = "w-80 2xl:w-96 opacity-100	cursor-pointer transition-all mb-4"
      document.querySelector("#how-is-work-section-image-1").className = "w-52 2xl:w-60 opacity-20  cursor-pointer transition-all"
      document.querySelector("#how-is-work-section-0").className = "text-center text-sm font-medium text-red"
      document.querySelector("#how-is-work-section-1").className = "text-center text-sm font-medium text-black"
    } else {
      document.querySelector("#how-is-work-section-bar-1").className = "cursor-pointer rounded-md w-2.5 bg-red flex-1 shadow-sm transition-all"
      document.querySelector("#how-is-work-section-bar-0").className = "cursor-pointer w-1 bg-light_gray_3 flex-1 transition-all"
      document.querySelector("#how-is-work-section-image-1").className = "w-80 2xl:w-96 opacity-100	cursor-pointer transition-all mt-4"
      document.querySelector("#how-is-work-section-image-0").className = "w-52 2xl:w-60 opacity-20  cursor-pointer transition-all"
      document.querySelector("#how-is-work-section-1").className = "text-center  text-sm font-medium text-red"
      document.querySelector("#how-is-work-section-0").className = "text-center text-sm font-medium text-black"
    }
  }
  slider_how_is_work.events.on('transitionStart', customizedFunction);
}

if (document.querySelector(".our-numbers")) {
  const slider_our_numbers = tns({
    container: '.our-numbers',
    items: 1,
    responsive: {
      640: {
        items: 2
      },
      1024: {
        items: 3
      }
    },
    "loop": false,
    controlsText: ["", ""],
    mouseDrag: true,
    slideBy: 1,
    autoplay: true,
    controlsPosition: "bottom",
    navPosition: "bottom",
    nextButton: false,
    prevButton: false
  });
  console.log({slider_our_numbers})
}
if (document.querySelector(".testimonials-slider")) {
  const slider_testimonials = tns({
    container: '.testimonials-slider',
    items: 1,
    gutter: 10,
    responsive: {
      640: {
        items: 1,
        slideBy: 1,
      },
      1024: {
        items: 2,
        slideBy: 2,
      }
    },
    controlsText: ["<div>" + svg + "</div>", "<div>" + svg + "</div>"],
    "loop": true,
    mouseDrag: true,
    slideBy: 1,
    autoplay: true,
    controlsPosition: "bottom",
    navPosition: "bottom",
    nextButton: false,
    prevButton: false
  });
}

if (document.querySelector(".skin-slider")) {
  const skin_slider = tns({
    container: '.skin-slider',
    items: 3,
    center: false,
    controls: false,
    mouseDrag: true,
    loop: true,
    touch: true,
    slideBy: 1,
    startIndex: 0,
    gutter: 24,
    edgePadding: 24,
    nextButton: false,
    prevButton: false,
    responsive: {
      640: {
        items: 3,
        slideBy: 1,
        startIndex: 1,
      },
      768: {
        items: 5,
        slideBy: 1,
      },
      1024: {
        disable: true,
      }
    },
  });
}

if (document.querySelector(".home-slider")) {
  const home_slider = tns({
    container: '.home-slider',
    items: 1,
    slideBy: 1,
    startIndex: 0,
    center: false,
    controls: false,
    mouseDrag: false,
    loop: false,
    touch: false,
    nextButton: false,
    prevButton: false,
    autoHeight: true
  });

  // document.querySelector("#give-notice").onclick = function () {
  //   home_slider.goTo(2);
  // }

  // document.querySelector("#back-to-notices").onclick = function () {
  //   home_slider.goTo(1);
  // }

  // document.querySelector("#open-review-modal").onclick = function () {
  //   home_slider.goTo(1);
  // }
}
if (document.querySelector(".home-slider-xs")) {
  const home_slider_xs = tns({
    container: '.home-slider-xs',
    items: 1,
    slideBy: 1,
    startIndex: 0,
    center: false,
    controls: false,
    mouseDrag: false,
    loop: false,
    touch: false,
    nextButton: false,
    prevButton: false,
    autoHeight: true
  });

  document.querySelector("#give-notice-xs").onclick = function () {
    home_slider_xs.goTo(2);
  }

  document.querySelector("#back-to-notices-xs").onclick = function () {
    home_slider_xs.goTo(1);
  }

  document.querySelector("#open-review-modal-xs").onclick = function () {
    home_slider_xs.goTo(1);
  }
}


if (document.querySelector(".slider-week")) {
  const slider_week = tns({
    container: '.slider-week',
    items: 1,
    slideBy: 1,
    loop: true,
    controlsText: ["<div>" + svg + "</div>", "<div>" + svg + "</div>"],
    controlsPosition: "bottom",
    navPosition: "bottom",
    mouseDrag: true,
    autoplay: false,
    nextButton: false,
    prevButton: false
  });
}

if (document.querySelector(".slider-week-xs")) {
  const slider_week_xs = tns({
    container: '.slider-week-xs',
    items: 1,
    slideBy: 1,
    loop: true,
    controlsText: ["<div>" + svg + "</div>", "<div>" + svg + "</div>"],
    controlsPosition: "bottom",
    navPosition: "bottom",
    mouseDrag: true,
    autoplay: false,
    nextButton: false,
    prevButton: false
  });
}
