const nav = document.querySelector("#nav-bar");
if (nav) {
    const NavTop = nav.offsetTop;
    function fixNavBar() {
        if (window.scrollY > NavTop) {
            if (document.querySelector('#nav-bar')) {
                document.querySelector('#nav-bar').style.cssText = "position: fixed; width: 100%; top:0; background-color: #000; z-index: 20; box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);  @media (min-width: 768px) {padding-left: 6rem; padding-right: 6rem;}";
            }
            if (document.querySelector('#ol-menu-on-scroll')) {
                document.querySelector('#ol-menu-on-scroll').classList.remove("lg:block")
            }
            if (document.querySelector('#menu-on-scroll')) {
                document.querySelector('#menu-on-scroll').classList.add("lg:flex")
            }
            if (document.querySelector('.auth-btn')) {
                document.querySelector('.auth-btn').style.cssText = "display: flex; ";
            }
            if (document.querySelector('#logo-on-scroll')) {
                document.querySelector('#logo-on-scroll').style.cssText = "height: 3rem";
            }
        } else {
            if (document.querySelector('#ol-menu-on-scroll')) {
                document.querySelector('#ol-menu-on-scroll').classList.add("lg:block")
            }
            if (document.querySelector('#menu-on-scroll')) {
                document.querySelector('#menu-on-scroll').classList.remove("lg:flex")
            }
            if (document.querySelector('#nav-bar')) {
                document.querySelector('#nav-bar').removeAttribute('style')
            }
            if (document.querySelector('.auth-btn')) {
                document.querySelector('.auth-btn').removeAttribute('style')
            }
            if (document.querySelector('#logo-on-scroll')) {
                document.querySelector('#logo-on-scroll').removeAttribute('style');
            }
        }
    }

    window.addEventListener("scroll", fixNavBar);
}
