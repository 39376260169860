if (document.querySelector("#detail-modal .modal-close")) {
    document.querySelectorAll("#detail-modal .modal-close").forEach(function(e){
        e.onclick = function () {
            document.querySelector("#detail-modal").style.display = "none";
        }
    });
}
if (document.querySelector("#edit-modal .modal-close")) {
    document.querySelectorAll("#edit-modal .modal-close").forEach(function(e){
        e.onclick = function () {
            document.querySelector("#edit-modal").style.display = "none";
        }
    });
}
if (document.querySelector("#review-modal .modal-close")) {
    document.querySelectorAll("#review-modal .modal-close").forEach(function(e){
        e.onclick = function () {
            document.querySelector("#review-modal").style.display = "none";
        }
    });
}

function openAgendaEvent (info) {
    const props = info.event.extendedProps;
    if(props.status == "pending"){
        document.querySelectorAll("#detail-modal .finished").forEach(function (e){ e.style.display = "none"});
        document.querySelectorAll("#detail-modal .pending").forEach(function (e){ e.style.display = "block"});
    }else{
        document.querySelectorAll("#detail-modal .pending").forEach(function (e){ e.style.display = "none"});
        document.querySelectorAll("#detail-modal .finished").forEach(function (e){ e.style.display = "block"});
    }
    document.querySelectorAll("#detail-modal .coach").forEach(function (e){ e.innerHTML = props.coach});
    document.querySelectorAll("#detail-modal .date").forEach(function (e){ e.innerHTML = props.date_text});
    document.querySelectorAll("#detail-modal .coach_experience").forEach(function (e){ e.innerHTML = props.coach_experience});
    document.querySelectorAll("#detail-modal .coach_profession").forEach(function (e){ e.innerHTML = props.coach_profession});
    document.querySelectorAll("#detail-modal .debut").forEach(function (e){ e.innerHTML = props.debut});
    document.querySelectorAll("#detail-modal .fin").forEach(function (e){ e.innerHTML = props.fin});
    document.querySelectorAll("#detail-modal .review").forEach(function (e){ e.innerHTML = props.review});
    document.querySelectorAll("#detail-modal .sportif_experience").forEach(function (e){ e.innerHTML = props.sportif_experience});
    document.querySelector("#detail-modal").style.display = "block";

    document.querySelector("#detail-modal #noter").onclick = function(){
        document.querySelector("#detail-modal").style.display = "none";
        document.querySelector("#review-modal").style.display = "block";
    }

    document.querySelector("#detail-modal #edit-seance").onclick = function(){
        document.querySelector("#detail-modal").style.display = "none";


        document.querySelectorAll("#edit-modal .coach").forEach(function (e){ e.innerHTML = props.coach});
        document.querySelectorAll("#edit-modal .date").forEach(function (e){ e.innerHTML = props.date_text});
        document.querySelectorAll("#edit-modal .coach_experience").forEach(function (e){ e.innerHTML = props.coach_experience});
        document.querySelectorAll("#edit-modal .coach_profession").forEach(function (e){ e.innerHTML = props.coach_profession});
        document.querySelectorAll("#edit-modal .debut").forEach(function (e){ e.innerHTML = props.debut});
        document.querySelectorAll("#edit-modal .fin").forEach(function (e){ e.innerHTML = props.fin});
        document.querySelectorAll("#edit-modal .review").forEach(function (e){ e.innerHTML = props.review});
        document.querySelectorAll("#edit-modal .sportif_experience").forEach(function (e){ e.innerHTML = props.sportif_experience});

        document.querySelector("#edit-modal").style.display = "block";
    }

}
window.openAgendaEvent = openAgendaEvent
window.openAgendaEvent = openAgendaEvent
