import { Controller } from 'stimulus'
import { removeSpaceOrComma } from '../src/remove_space_or_comma'
import { addSpaceOrComma } from '../src/add_space_or_comma'

export default class extends Controller {
  static targets = ['couponCheckbox', 'couponCode', 'couponDiv', 'voucherDiv', 'voucherCheckbox',
    'couponError', 'couponSuccess', 'amount', 'voucher', 'voucherError', 'voucherSuccess', 'allPlatforms', 'payButton']
  static values = {
    id: String,
    errorMessageCode: String,
    errorMessageVoucher: String
  }
  connect() {
    this.fetchPaylaod()
    this.toggleCoupon()
    this.toggleVoucher()
  }

  toggleCoupon(){
    if(this.couponCheckboxTarget.checked === true){
      this.couponDivTarget.hidden = false
    }
    else{
      this.couponDivTarget.hidden = true
    }
  }

  toggleVoucher(){
    if(this.voucherCheckboxTarget.checked === true){
      this.voucherDivTarget.hidden = false
    }
    else{
      this.voucherDivTarget.hidden = true
    }
  }

  updateCoupon(){
    if(this.couponCodeTarget.value === ''){
      this.couponSuccessTarget.innerHTML = ''
      this.couponErrorTarget.innerHTML = this.errorMessageCodeValue
    }
    else{
      const url = `/payments/stock_code_coupon?code_coupon=${this.couponCodeTarget.value}&id=${this.idValue}`
      fetch(url)
        .then(response => response.json())
        .then(({ text, new_amount, status }) => {
          if( status == 400 ){
            this.couponSuccessTarget.innerHTML = ''
            this.couponErrorTarget.innerHTML = text
          }else{
            this.couponErrorTarget.innerHTML = ''
            this.couponCodeTarget.value = ''
            this.couponSuccessTarget.innerHTML = text
            this.amountTarget.innerHTML = addSpaceOrComma(new_amount, 'fr-FR')
            this.allPlatformsTarget.innerHTML = ''
            if (parseInt(new_amount) === 0) {
              window.location.reload();
            }
            this.fetchPaylaod()
          }
        })
    }
  }

  updateVoucher() {
    if(this.voucherTarget.value === ''){
      this.voucherSuccessTarget.innerHTML = ''
      this.voucherErrorTarget.innerHTML = this.errorMessageVoucherValue
    }else{
      const url = `/payments/stock_amount_voucher?amount=${this.voucherTarget.value}&id=${this.idValue}`
      fetch(url)
        .then(response => response.json())
        .then(({ text, status, new_amount}) => {
          if ( status == 400){
            this.voucherSuccessTarget.innerHTML = ''
            this.voucherErrorTarget.innerHTML = text
          }else{
            this.voucherErrorTarget.innerHTML = ''
            this.voucherTarget.value = ''
            this.voucherSuccessTarget.innerHTML = text
            this.amountTarget.innerHTML = addSpaceOrComma(new_amount, 'fr-FR')
            this.allPlatformsTarget.innerHTML = ''
            if (parseInt(new_amount) === 0) {
              window.location.reload();
            }
            this.fetchPaylaod()
          }
        })
    }
  }

  fetchPaylaod(){
    const url = '/payments/payload?id=' + this.idValue
    fetch(url)
      .then(response => response.json())
      .then(({  afrik_pay_store, callback_host, amount, reference, lang, text, token }) => {
        this.afrikPay(afrik_pay_store, reference, amount, callback_host, lang, text, token)
      })
  }
  afrikPay(afrik_pay_store, reference, amount, callback_host, lang, text, token){
    var date = new Date();
    var timestamp = date.getTime();
    var objectData = {
      store: afrik_pay_store, //Required - Provided by AfrikPay
      purchaseref: reference, //Required - Your unique transaction ID
      amount: amount, //Required - Purchase amount
      phonenumber : '', //Not required - Payer phone number
      email : '', //Not required - Payer email
      brand: 'AfrikPay eCommerce', //Not required -
      text: text, //Not required
      notifurl: `${callback_host}/payments/notif?id=${this.idValue}`, //Not required
      accepturl: `${callback_host}/payments/accept?id=${this.idValue}&token=${token}`, //Not required
      cancelurl: `${callback_host}/payments/cancel?id=${this.idValue}`, //Not required
      declineurl: `${callback_host}/payments/decline?id=${this.idValue}`, //Not required
      language: lang, //Not required, Default : en
    }
    if(parseInt(amount) != 0){
      renderBtns('all-platforms', ['afrikpay', 'campost_money', 'orange_money_cm', 'mtn_mobilemoney_cm', 'express_union_mobilemoney', 'paypal', 'visa', 'master_card'], 'pbtn-afp', objectData);
    }
  }

  removeCouponErrorMessage(){
    this.couponErrorTarget.innerHTML = ''
  }

  removeVoucherErrorMessage(){
    this.voucherErrorTarget.innerHTML = ''
  }

}
