import {Controller} from "stimulus"

export default class extends Controller {

  static targets = ["menu", 'toggleOptions', 'optionsSection', "menuBackdrop" ];


  initialize() {

    this.showMenu = false;
    this.menuTargets.forEach((element, index) => {
      element.hidden = true
    })
  }

  toggleDrawerMenu() {
    this.showMenu = !this.showMenu;
    if(this.showMenu){
      this.menuTargets.forEach((element, index) => {
        element.classList.remove("-translate-x-72");
      });
      this.menuBackdropTargets.forEach((element, index) => {
        element.classList.remove("hidden");
      });
    }else{
      this.menuTargets.forEach((element, index) => {
        element.classList.add("-translate-x-72");
      });
      this.menuBackdropTargets.forEach((element, index) => {
        element.classList.add("hidden");
      });
    }
  }


  toggleMobileMenu() {
    this.showMenu = !this.showMenu;
    this.menuTargets.forEach((element, index) => {
      element.hidden = !this.showMenu
    })
  }

  greet(event) {
    event.preventDefault();
    alert("Bonjour !");
  }

  displayOptions() {
    let state = this.toggleOptionsTargets[0];
    let section = this.optionsSectionTargets[0]

    if (state.checked) {
      section.classList.remove('hidden');
    } else {
      section.classList.add('hidden');
    }

  }
}
