import { Controller } from 'stimulus'
import { removeSpaceOrComma } from '../src/remove_space_or_comma'
import { addSpaceOrComma } from '../src/add_space_or_comma'

export default class extends Controller {
  static targets = ['amountAvailable', 'subscriptionPrice', 'netAmountToBePaid', 'text']

  connect() {
    this.display()
  }


  display () {
   const subscriptionPrice = removeSpaceOrComma(this.subscriptionPriceTarget.innerHTML)
    const amountAvailable = removeSpaceOrComma(this.amountAvailableTarget.innerHTML)
    const netAmountToBePaid = subscriptionPrice - amountAvailable
    if (netAmountToBePaid < 0)
    {
       this.netAmountToBePaidTarget.innerHTML = 0
      this.textTarget.innerHTML = `${addSpaceOrComma(Math.abs(netAmountToBePaid))} XAF vous seront versés dans votre chèque cadeaux CoachPerso237`
    } else {
       this.netAmountToBePaidTarget.innerHTML = addSpaceOrComma(netAmountToBePaid)
      this.textTarget.innerHTML = ''
    }

  }
}
