import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["heading"]
  connect() {
  }
  greet() {
    this.headingTarget.innerHTML = "Hello World"
  }
}
