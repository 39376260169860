import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import { Autocomplete } from 'stimulus-autocomplete'
import {Alert, Autosave, Dropdown, Modal, Popover, Slideover, Tabs, Toggle} from "tailwindcss-stimulus-components"
const application = Application.start()
const context = require.context(".", true, /\.js$/)
application.load(definitionsFromContext(context))
application.register('autocomplete', Autocomplete)

application.register('alert', Alert)
application.register('autosave', Autosave)
application.register('dropdown', Dropdown)
application.register('modal', Modal)
application.register('tabs', Tabs)
application.register('popover', Popover)
application.register('toggle', Toggle)
application.register('slideover', Slideover)
